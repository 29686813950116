import { filter } from 'rxjs/operators';
import { interval, Subscription } from 'rxjs';
import { SwUpdate } from '@angular/service-worker';
import { BrowserService } from './core/browser.service';
import { environment } from './../environments/environment';
import { GoogleAnalyticsService } from 'ngx-google-analytics';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { PageviewlogService } from './core/services/pageview-log.service';
import { ApplicationRef, Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { NgcCookieConsentConfig, NgcCookieConsentService, NgcStatusChangeEvent } from 'ngx-cookieconsent';

@Component({
  selector: 'brf-news-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnDestroy, OnInit {
  title = 'BRF-Mappen';
  mobileView = false;
  fbPixelElemId = 'scriptFBPixel';
  private statusChangeSubscription!: Subscription;
  subs = new Subscription();
  updateSubscriptionWasRegistered = false;

  cookieConfig: NgcCookieConsentConfig = {
    cookie: {
      domain: environment.domain.replace('https://', '').replace('http://', '').replace(':4206', '')
    },
    palette: {
      popup: {
        background: '#fff',
        border: '#ff8519'
      },
      button: {
        background: '#6da6da'
      }
    },
    theme: 'classic',
    type: 'opt-in'
  };
  static instance: AppComponent;

  constructor(
    private router: Router,
    private update: SwUpdate,
    private appRef: ApplicationRef,
    private browserService: BrowserService,
    private gaService: GoogleAnalyticsService,
    private ccService: NgcCookieConsentService,
    private pageviewlogService: PageviewlogService
  ) {
    if (this.browserService.isbrowser) {
      if ((location.hostname && location.hostname.startsWith('www.')) || location.pathname.startsWith('/suppliers')) {
        let newPath = location.href.replace('//www.', '//').replace('/suppliers', '/hitta-leverantor');
        location.replace(newPath);
      }
      this.mobileView = window?.innerWidth < 760;
      this.browserService.isMobileScreenSubject.next(this.mobileView);
      this.browserService.isDesktopScreenSubject.next(!this.mobileView);

      window['isFirstLoad'] = true;
      window['isRequiredReloadWhenNavigate'] = false;

      AppComponent.instance = this;

      this.updateClient();
      this.checkUpdate();

      this.subs.add(
        this.router.events.subscribe((val: any) => {
          if (this.browserService.isbrowser) {
            if (val instanceof NavigationStart && window['isRequiredReloadWhenNavigate'] == true) {
              console.log('Reloading...');
              window['isRequiredReloadWhenNavigate'] = false;
              setTimeout(() => {
                location.reload();
              }, 100);
            }
          }
        })
      );

      const fbPixelId = environment.fbPixelId;
      var t = document.createElement('script');
      t.type = 'text/javascript';
      t.id = this.fbPixelElemId;
      t.innerHTML = `
        !function(f,b,e,v,n,t,s)
        {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
        n.callMethod.apply(n,arguments):n.queue.push(arguments)};
        if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
        n.queue=[];t=b.createElement(e);t.async=!0;
        t.src=v;s=b.getElementsByTagName(e)[0];
        s.parentNode.insertBefore(t,s)}(window,document,'script',
        'https://connect.facebook.net/en_US/fbevents.js');
        fbq('init', '${fbPixelId}');
        fbq('track', 'PageView');`;
      const existingScriptTag = document.getElementById(t.id);
      if (!existingScriptTag) document.body.appendChild(t);
    }
  }

  ngOnInit() {
    console.log('ng v15 working !!!');
    this.showCookieConsent();
    this.setUpOurOwnAnalytics();
    if (this.browserService.isbrowser) {
      if (this.ccService.hasConsented()) this.allowCookieConsent();
      else this.denyCookieConsent();

      this.statusChangeSubscription = this.ccService.statusChange$.subscribe((event: NgcStatusChangeEvent) => {
        if (event.status === 'allow') {
          console.log('Cookies allowed');
          this.allowCookieConsent();
        } else if (event.status === 'deny') {
          console.log('Cookies denied');
          this.denyCookieConsent();
        }
      });
    }
  }

  showCookieConsent() {
    this.cookieConfig.content = this.cookieConfig.content || {};
    this.cookieConfig.content.message = 'Den här webbplatsen använder cookies. Genom att fortsätta använda vår webbplats accepterar du att cookies används.';
    this.cookieConfig.content.allow = 'Tillåt cookies';
    this.cookieConfig.content.deny = 'Avböj';
    this.cookieConfig.content.link = 'Läs vår policy';
    this.cookieConfig.content.href = 'https://cdn.brfmappen.se/azure-prod/privacy_terms/Fastighetsmedia_-_Integritetspolicy_och_Anvandarvillkor.pdf';

    this.mobileView ? (this.cookieConfig.position = 'top') : (this.cookieConfig.position = 'bottom');
    
    try {
      this.ccService.destroy();
    } catch (err) {
      console.warn('[CookieConsent] Failed to destroy banner:', err);
    }

    this.ccService.init(this.cookieConfig);

  }

  showCookieSettings() {
    this.cookieConfig.content = this.cookieConfig.content || {};
    this.cookieConfig.content.message = 'Den här webbplatsen använder cookies. Genom att fortsätta använda vår webbplats accepterar du att cookies används.';
    this.cookieConfig.content.allow = 'Tillåt cookies';
    this.cookieConfig.content.deny = 'Avböj';
    this.cookieConfig.content.link = 'Läs vår policy';
    this.cookieConfig.content.href = 'https://cdn.brfmappen.se/azure-prod/privacy_terms/Fastighetsmedia_-_Integritetspolicy_och_Anvandarvillkor.pdf';

    this.mobileView ? (this.cookieConfig.position = 'top') : (this.cookieConfig.position = 'bottom');

    const domain = window.location.hostname.includes('beta.brfmappen.se') ? '.beta.brfmappen.se' : '.brfmappen.se';
    document.cookie = `cookieconsent_status=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; domain=${domain}`;
    //document.cookie = 'cookieconsent_status=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';

    try {
      this.ccService.destroy();
    } catch (err) {
      console.warn('[CookieConsent] Failed to destroy banner:', err);
    }

    setTimeout(() => {      
      this.ccService.init(this.cookieConfig);
    }, 100);
  }

  allowCookieConsent() {
    this.gaService.gtag('consent', 'update', {
      ad_storage: 'granted',
      analytics_storage: 'granted',
      ad_user_data: 'granted',
      ad_personalization: 'granted'
    });
    window['ga-disable-' + environment.GoogleAnalyticsCode] = false;
    this.loadFacebookPixel();
  }

  denyCookieConsent() {
    this.gaService.gtag('consent', 'default', {
      ad_storage: 'denied',
      analytics_storage: 'denied',
      ad_user_data: 'denied',
      ad_personalization: 'denied'
    });
    window['ga-disable-' + environment.GoogleAnalyticsCode] = true;
    this.removeFacebookPixel();
  }

  loadFacebookPixel(): void {
    window['fbq']('consent', 'grant');
    window['fbq']('track', 'PageView');
  }

  removeFacebookPixel(): void {
    window['fbq']('consent', 'revoke');
  }

  setUpOurOwnAnalytics() {
    this.subs.add(
      this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe((event: any) => {
        if (this.browserService.isbrowser) {
          this.pageviewlogService.capturePageview(event?.urlAfterRedirects).subscribe();
        }
      })
    );
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any): void {
    const flag = window?.innerWidth < 760;
    this.browserService.isMobileScreenSubject.next(flag);
    this.browserService.isDesktopScreenSubject.next(!flag);
  }

  updateClient() {
    if (this.updateSubscriptionWasRegistered) return;
    this.updateSubscriptionWasRegistered = true;

    if (!this.update.isEnabled) {
      console.log('Update not Enabled');
      return;
    }

    console.log('Registering subscription for watching update...');
    this.subs.add(
      this.update.available.subscribe(event => {
        console.log(`current`, event.current, `available `, event.available);
        if ('serviceWorker' in navigator) {
          navigator.serviceWorker.getRegistrations().then(function (registrations) {
            let pArr: Promise<boolean>[] = [];
            for (let registration of registrations) {
              pArr.push(registration.unregister());
            }
            if ('caches' in window) {
              caches.keys().then(function (keyList) {
                for (let k of keyList) pArr.push(caches.delete(k));
              });
            }
            Promise.all(pArr).then(result => {
              console.log('Cleared all cached data.');
              window['isRequiredReloadWhenNavigate'] = true;
            });
          });
        }
      })
    );
  }

  checkUpdate() {
    this.subs.add(
      this.appRef.isStable.subscribe(isStable => {
        if (isStable) {
          const timeInterval = interval(2 * 60 * 60 * 1000);

          timeInterval.subscribe(() => {
            this.update.checkForUpdate().then(() => {});
          });
        }
      })
    );
  }

  onActivate(event) {
    if (this.browserService.isbrowser) {
      setTimeout(() => {
        window.scroll({
          top: 10,
          left: 10,
          behavior: 'smooth'
        });
      }, 0);
    }
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
    if (this.statusChangeSubscription && this.statusChangeSubscription != undefined) this.statusChangeSubscription.unsubscribe();
  }
}
